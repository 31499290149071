import { CTA, GRichText } from "@/components";
import { BannerSlot, Link } from "@jog/react-components";
import { responsiveImages } from "@jog/shared";
import { asLink } from "@prismicio/helpers";
import classNames from "classnames";
export const FiveSlotBanner = ({ slice: { primary, items }, className }) => {
    var _a, _b;
    const { getSizes } = responsiveImages();
    const bigImageSizes = ["575", "768", "505", "700", "50vw"];
    const smallImageSizes = ["295", "400", "255", "350", "25vw"];
    return (<div className={classNames(className, "grid grid-cols-1 gap-0.5 md:grid-cols-2")}>
            <div>
                <Link to={asLink(primary.primary_button_url)}>
                    <BannerSlot text={primary.text} textAlignment={primary.text_alignment} textColor={primary.text_color} textTopPosition={primary.text_top_position} buttonText={primary.primary_button_label} buttonTopPosition={primary.primary_button_top_position || 0} buttonBgColor={primary.buttons_bg_color || ""} buttonTextColor={primary.buttons_text_color || ""} colorHover={primary.buttons_hover_text_color || ""} bgColorHover={primary.buttons_hover_bg_color || ""} borderWidth={primary.buttons_border_width || 0} borderColor={primary.buttons_border_color || ""} fontSizeMobile={primary.buttons_font_size_mobile || ""} sizes={getSizes(bigImageSizes)} src={primary.primary_banner_image_desktop.url || ""} height={((_a = primary.primary_banner_image_desktop.dimensions) === null || _a === void 0 ? void 0 : _a.height) || 0} width={((_b = primary.primary_banner_image_desktop.dimensions) === null || _b === void 0 ? void 0 : _b.width) || 0} RichText={GRichText} CTA={CTA}/>
                </Link>
            </div>
            <div className="grid h-full w-full grid-cols-2 gap-0.5">
                {items.map((item, index) => {
            var _a, _b;
            return (<div key={index}>
                        <Link to={asLink(item.button_url)}>
                            <BannerSlot text={primary.text} textAlignment={primary.text_alignment} textColor={primary.text_color} textTopPosition={primary.text_top_position_for_secondary} buttonText={item.button_label} buttonTopPosition={primary.secondary_buttons_top_position || 0} buttonBgColor={primary.buttons_bg_color || ""} buttonTextColor={primary.buttons_text_color || ""} colorHover={primary.buttons_hover_text_color || ""} bgColorHover={primary.buttons_hover_bg_color || ""} borderWidth={primary.buttons_border_width || 0} borderColor={primary.buttons_border_color || ""} fontSizeMobile={primary.buttons_font_size_mobile || ""} sizes={getSizes(smallImageSizes)} src={item.image.url || ""} height={((_a = item.image.dimensions) === null || _a === void 0 ? void 0 : _a.height) || 0} width={((_b = item.image.dimensions) === null || _b === void 0 ? void 0 : _b.width) || 0} RichText={GRichText} CTA={CTA}/>
                        </Link>
                    </div>);
        })}
            </div>
        </div>);
};
