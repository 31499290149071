import { CTA, GRichText } from "@/components";
import { BannerSlot, Link } from "@jog/react-components";
import { responsiveImages } from "@jog/shared";
import { asImageSrc, asLink } from "@prismicio/helpers";
import classNames from "classnames";
const classMap = {
    2: "grid grid-cols-1 sm:grid-cols-2",
    3: "grid grid-cols-1 sm:grid-cols-3",
    4: "grid grid-cols-2 lg:grid-cols-4",
    6: "grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6",
};
const sizeMap = {
    2: ["575", "380", "480", "680", "50vw"],
    3: ["180", "250", "320", "460", "100/3vw"],
    4: ["280", "380", "490", "340", "25vw"],
    6: ["280", "380", "490", "340", "100/6vw"],
};
const slots = {
    two_slot_banner: 2,
    three_slot_banner: 3,
    four_slot_banner: 4,
    six_slot_banner: 6,
};
export const BannerSlots = ({ slice: { primary, items, slice_type }, className, }) => {
    const { getSizes } = responsiveImages();
    return (<div className={classNames(className, "gap-0.5", classMap[slots[slice_type]])}>
            {items.map((item, index) => {
            var _a, _b;
            return (<div key={index}>
                    <Link to={asLink(item.button_url)}>
                        <BannerSlot buttonText={item === null || item === void 0 ? void 0 : item.button_label} buttonTopPosition={primary.buttons_top_position || 0} buttonBgColor={primary.buttons_bg_color || ""} buttonTextColor={primary.buttons_text_color || ""} colorHover={primary.buttons_hover_text_color || ""} bgColorHover={primary.buttons_hover_bg_color || ""} borderWidth={primary.buttons_border_width || 0} borderColor={primary.buttons_border_color || ""} fontSizeMobile={primary.buttons_font_size_mobile || ""} src={asImageSrc(item.image) || ""} height={((_a = item.image.dimensions) === null || _a === void 0 ? void 0 : _a.height) || 0} width={((_b = item.image.dimensions) === null || _b === void 0 ? void 0 : _b.width) || 0} sizes={getSizes(sizeMap[slots[slice_type]])} quality={100} RichText={GRichText} CTA={CTA}/>
                    </Link>
                </div>);
        })}
        </div>);
};
